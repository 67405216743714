import React, { useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Center,
} from "@chakra-ui/react";
import QRCode from "qrcode.react";

const QRModal = ({ isOpen, onClose, email }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>QR Code for Email</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Center>
                        <QRCode value={email} size={200} />
                    </Center>
                </ModalBody>
                <ModalFooter>
                    <Center>
                        <p>Scan this QR code to view the email on your phone.</p>
                    </Center>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default QRModal;
