import './App.css';
import EmailApp from './components/EmailComponent';
function App() {
  return (
    <div>
      <EmailApp />
    </div>
  );
}

export default App;
