import React, { useState, useEffect, useCallback } from "react";
import {
  ChakraProvider,
  Box,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Center,
  Spinner,
  Text,
  Alert,
  AlertIcon,
  Stack,
  IconButton,
  Heading,
  Image,
  TableContainer,
  TableCaption,
  useClipboard,
  Flex,
  ButtonGroup
} from "@chakra-ui/react";
import { FaCopy, FaCheck } from "react-icons/fa";
import EmailDetailsModal from "./EmailDetailsModal";
import QRModal from "./QRmodal";

const EmailApp = () => {
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isChanged, setIsChanged] = useState(false);
  const { onCopy, hasCopied } = useClipboard(email);

  const [isQRModalOpen, setIsQRModalOpen] = useState(false);

  const generateNewEmail = async () => {
    try {
      setIsLoading(true);
      setEmails(null);
      const response = await fetch("https://api-server.temp-mail.lol/api/generate_email");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setEmail(data.email);
      setEmails([]);
    } catch (error) {
      console.error("Error fetching generated email:", error);
      setError("Failed to generate email.");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEmails = useCallback(async (email) => {
    try {
      setIsLoading(true);
      setIsChanged(false);
      if (email) {
        const response = await fetch(`https://api-server.temp-mail.lol/api/emails/${email}`);
        const data = await response.json();
        setEmails(data);
      }
    } catch (error) {
      console.error(`Error fetching emails for ${email}:`, error);
      setError("Failed to fetch emails.");
    } finally {
      setIsLoading(false);
    }
  }, []);



  const handleRefreshClick = () => {
    setEmails(null)
    fetchEmails(email);
  };

  const toggleQRModal = () => {
    setIsQRModalOpen(!isQRModalOpen);
  };

  const saveEmail = () => {
    toggleQRModal();
  };
  const handleClick = () => {
    window.open("https://tronics.dev", "_blank");
  };

  useEffect(() => {
    generateNewEmail();
  }, []);

  return (
    <Stack>
      <Stack bg="black" padding={5}>
        <Flex justifyContent={"flex-end"}>
          <ButtonGroup
            paddingRight="4px"
            variant="outline"
            colorScheme="blue"
            size={{ base: "xs", md: "md", lg: "md" }}
          >
            <Button onClick={handleClick}>Contact</Button>
            <Button onClick={handleClick}>About Us</Button>
          </ButtonGroup>
        </Flex>
        <Center>
          <Image
            src="https://i.ibb.co/2qrqkZX/tempmail-removebg-preview.png"
            alt="logo"
            width={{ base: "40%", lg: "10%", xl: "10%" }}
            height="8%"
          />
        </Center>

        <Center>
          <Stack
            direction="column"
            spacing={4}
            align="center"
            bg="gray.800"
            border={"3px dotted #3498db"}
            padding="3%"
            borderRadius={"20px"}
          >
            <Stack width="100%">
              <Heading fontSize={"xl"} color={"white"}>
                Your email address is:
              </Heading>
              <Stack direction="row">
                <Input
                  type="text"
                  value={email}
                  onChange={(e) => {
                    setIsChanged(true);
                    setEmail(e.target.value)
                  }
                  }
                  placeholder="Enter desired email"
                  isDisabled={isLoading}
                  className="email-input-field"
                  bg="white"
                  color="black"
                />
                <IconButton
                  aria-label={hasCopied ? "Email Copied!" : "Copy Email"}
                  icon={hasCopied ? <FaCheck /> : <FaCopy />}
                  onClick={onCopy}
                  colorScheme={hasCopied ? "green" : "teal"}
                  size="md" // Adjust the size as needed
                />
              </Stack>
            </Stack>
            <Stack
              width="100%"
              direction={{ base: "column", lg: "row", xl: "row" }}
            >
              <Button
                onClick={generateNewEmail}
                isLoading={isLoading}
                bg="white"
                color="black"
              >
                Generate New Email
              </Button>
              <Button
                onClick={handleRefreshClick}
                isLoading={isLoading}
                bg="white"
                color="black"
              >
                Refresh {isChanged && "*"}
              </Button>
              <Button
                onClick={saveEmail}
                isLoading={isLoading}
                bg="white"
                color="black"
              >
                Scan QR Code
              </Button>
              <QRModal
                isOpen={isQRModalOpen}
                onClose={toggleQRModal}
                email={email}
              />
            </Stack>
            <Alert status="info">
              <AlertIcon />
              Do not erase "@temp-mail.lol" from the link
            </Alert>
            {
              emails ? <Alert status="success">
                <AlertIcon />
                Successfully Fetched emails
              </Alert> : <Alert status="loading">
                <AlertIcon />
                Fetching Email
              </Alert>
            }
          </Stack>
        </Center>
      </Stack>
      <Stack height={"100%"}>

        <Center>
          <Stack minWidth={"50%"}>
            <TableContainer>
              <Table variant="striped" colorScheme="teal" size="sm">
                <TableCaption>{`Mailbox for ${email}`}</TableCaption>
                <Thead>
                  <Tr>
                    <Th color="black">Sender</Th>
                    <Th color="black">Date</Th>
                    <Th color="black">Subject</Th>
                    <Th color="black">Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {emails && emails.length > 0 ? (
                    emails.map((email) => (
                      <Tr key={email.id}>
                        <Td color="black">
                          {email.sender.substring(
                            email.sender.indexOf("<") + 1,
                            email.sender.indexOf(">")
                          )}
                        </Td>
                        <Td color="black">{email.date}</Td>
                        <Td color="black">{email.subject}</Td>
                        <Td color="black">
                          <EmailDetailsModal email={email} />
                        </Td>
                      </Tr>
                    ))
                  ) : (
                    <Tr>
                      <Td colSpan={4}>
                        <Center>
                          {error ? (
                            <Alert status="error">
                              <AlertIcon />
                              {error}
                            </Alert>
                          ) : (
                            <Center>Mailbox is empty.</Center>
                          )}
                        </Center>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Stack>
        </Center>
      </Stack>

      <Stack
        bg="gray.800"
        pt="4"
        pb="4"
        pl="12"
        pr="12"
        sx={{
          position: emails && emails.length > 2 ? "relative" : "fixed",
          bottom: 0,
          width: "100%",
          textAlign: "center",
          color: "white",
        }}
      >
        <Center>
          <Text fontSize="md">
            Copyright &copy; 2023 tronics.dev. All rights reserved.
          </Text>
        </Center>
      </Stack>
    </Stack>
  );
};

export default EmailApp;
