import { useState } from "react";
import {
  Td,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Text,
  Input,
  Center,
  Flex,
  IconButton,
} from "@chakra-ui/react";

import { FaDownload } from "react-icons/fa";


const EmailDetailsModal = ({ email }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  return (
    <>
      <Button colorScheme="teal" variant="outline" onClick={onOpen} size="sm">
        View
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="xl"
        closeOnOverlayClick={false}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Email Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDirection="column">
              <Flex flexDirection="row" alignItems="center">
                <Text fontWeight="bold" width="15%">
                  From
                </Text>
                <Text width="3%">: </Text>
                <Input value={email.sender} />
              </Flex>

              <Flex flexDirection="row" alignItems="center" pt="10px">
                <Text fontWeight="bold" width="15%">
                  To
                </Text>
                <Text width="3%">: </Text>

                <Input value={email.receiver} />
              </Flex>

              <Flex flexDirection="row" alignItems="center" pt="10px">
                <Text fontWeight="bold" width="15%">
                  Date:
                </Text>
                <Text width="3%">: </Text>

                <Input value={email.date} />
              </Flex>

              <Flex flexDirection="row" alignItems="center" pt="10px">
                <Text fontWeight="bold" width="15%">
                  Subject
                </Text>
                <Text width="3%">: </Text>
                <Input value={email.subject} />
              </Flex>
              {email.attachments && email.attachments.length > 0 && (
                <Flex flexDirection="column" pt="10px">
                  <Text fontWeight="bold" pb="4px">
                    Attachments:
                  </Text>
                  {email.attachments.map((attachment, index) => (
                    <Stack
                      direction={"row"}
                      key={index}
                    >
                      <Center justifyContent={"space-between"}>
                        <Text fontWeight="bold" fontSize={"sm"}>
                          {index + 1}. {attachment.filename}
                        </Text>
                        <IconButton
                          ml={2}
                          aria-label={`Download ${attachment.filename}`}
                          icon={<FaDownload />}
                          size="sm"
                          onClick={() => {
                            // Create a Blob with the base64 content and the content type
                            const blob = new Blob([Uint8Array.from(atob(attachment.content), c => c.charCodeAt(0))], { type: attachment.content_type });

                            // Create a temporary URL for the Blob
                            const url = URL.createObjectURL(blob);

                            // Create an anchor element to trigger the download
                            const a = document.createElement("a");
                            a.href = url;
                            a.download = attachment.filename;

                            // Append the anchor element to the document and click it
                            document.body.appendChild(a);
                            a.click();

                            // Remove the anchor element from the document
                            document.body.removeChild(a);

                            // Revoke the URL to free up resources
                            URL.revokeObjectURL(url);
                          }}
                        />
                      </Center>
                    </Stack>
                  ))}
                </Flex>
              )}
            </Flex>

            <p>
              <strong>Message</strong>
            </p>
            <Stack
              padding={"10px"}
              border={"2px dotted"}
              borderRadius="1%"
              minHeight="320px"
            >
              <div dangerouslySetInnerHTML={{ __html: email.message }} />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EmailDetailsModal;
